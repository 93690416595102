import { parseISO } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import { AccumulatorInstrumentName, AccumulatorTrade } from '@/models/trade';
import { formatDateLocalized } from '@/utils/locale';
import { DATE_LONG_FORMAT, useFormattingContext } from '@/utils/format';
import { isForwardAccu, isTargetAccu } from '@/utils/predicates';
import { CalendarHeaderInfosContainer } from './CalendarHeaderInfosContainer';
import { computeWay } from '../utils/computeWay';
import {
  DecoratedFields,
  FxoStandardForwardAccumulatorCalendarFields,
  FxTargetAccumulatorCalendarFields,
  FxoSquareTargetAccumulatorCalendarFields,
  FxoPauseTargetAccumulatorCalendarFields,
} from '@/models/calendar';
import { Currency } from '@/models/currency';
import { isDefined } from '@sgme/fp';

interface CalendarInfosProps {
  trade: AccumulatorTrade;
  computedFields:
    | DecoratedFields<FxoStandardForwardAccumulatorCalendarFields>[]
    | DecoratedFields<FxTargetAccumulatorCalendarFields>[]
    | DecoratedFields<FxoSquareTargetAccumulatorCalendarFields>[]
    | DecoratedFields<FxoPauseTargetAccumulatorCalendarFields>[];
}

export function CalendarHeaderInfos({ trade, computedFields }: CalendarInfosProps) {
  const { maturitydate, currencyPair, date, instrumentName, xOneCalendar, strikeDown, strikeUp, description } = trade;
  const { formatAmountWithPrecision, formatAmount, mapTrade } = useFormattingContext();

  const [ccy1, ccy2] = trade.currencyPair.split('/') as [Currency, Currency];
  const deliveranceData = getDeliveranceDate(trade);

  const { strike, nominal } = mapTrade(trade);

  const formattedMaturityDate = formatDateLocalized(parseISO(maturitydate), DATE_LONG_FORMAT);
  const formattedDate = formatDateLocalized(parseISO(date), DATE_LONG_FORMAT);

  let barrier;

  if (isForwardAccu(instrumentName, trade) && trade.barriers.length > 0) {
    // eslint-disable-next-line prefer-destructuring
    barrier = trade.barriers[0].barrierLevel[0];
  }

  // we need to check if we have the same strike in every row
  const firstStrike = xOneCalendar[0]?.strike;
  const isSameStrikes = xOneCalendar.every((row) => row?.strike === firstStrike);

  const firstStrikeUp = xOneCalendar[0]?.strike1;
  const firstStrikeDown = xOneCalendar[0]?.strike2;

  const kiUp = Math.max(
    ...(xOneCalendar.map((row) => {
      if (isDefined(row.step2)) {
        return row.step2;
      }
    }) as number[]),
  );
  const kiDown = Math.min(
    ...(xOneCalendar.map((row) => {
      if (isDefined(row.step1)) {
        return row.step1;
      }
    }) as number[]),
  );

  const firstKiUp = xOneCalendar[0]?.step2;
  const isSameKiUps = xOneCalendar.every((row) => row?.step2 === firstKiUp);

  const firstKiDown = xOneCalendar[0]?.step1;
  const isSameKiDowns = xOneCalendar.every((row) => row?.step1 === firstKiDown);

  const isSameStrikesUp = xOneCalendar.every((row) => row?.strike1 === firstStrikeUp);
  const isSameStrikesDown = xOneCalendar.every((row) => row?.strike2 === firstStrikeDown);

  // const differentStrikesUpTab = !isSameStrikesUp && xOneCalendar.filter((row) => row.strike1 !== firstStrikeUp);
  // const differentStrikeUp = differentStrikesUpTab && differentStrikesUpTab[0].strike1;

  // const differentStrikesDownTab = !isSameStrikesDown && xOneCalendar.filter((row) => row.strike2 !== firstStrikeDown);
  // const differentStrikeDown = differentStrikesDownTab && differentStrikesDownTab[0].strike2;

  // we need to check if we have the same strike in every row
  const firstLeverage = xOneCalendar[0]?.leverage;
  const isSameLeverage = xOneCalendar.every((row) => row?.leverage === firstLeverage);

  const strikeMessage = trade.instrumentName === 'FxoSquareTargetAccumulator' ? 'referenceStrike' : 'strike';

  return (
    <>
      <div className="calendar-header-info">
        <div className="card-grid-row">
          <CalendarHeaderInfosContainer title="expiry" instrumentName={trade.instrumentName}>
            <FormattedMessage id="app.calendar.expiry" values={{ value: formattedMaturityDate }} />
          </CalendarHeaderInfosContainer>
          <CalendarHeaderInfosContainer title="currencyPair" instrumentName={trade.instrumentName}>
            <FormattedMessage id="app.calendar.currencyPair" values={{ value: currencyPair }} />
          </CalendarHeaderInfosContainer>

          <CalendarHeaderInfosContainer title="initialNominal" instrumentName={trade.instrumentName}>
            <FormattedMessage id="app.calendar.initialNominal" values={{ value: nominal }} />
          </CalendarHeaderInfosContainer>

          <CalendarHeaderInfosContainer title="way" instrumentName={trade.instrumentName}>
            <FormattedMessage id={`app.calendar.${computeWay(trade)}`} values={{ amountCurrency: trade.amountCurrency }} />
          </CalendarHeaderInfosContainer>

          {isNotFxoPauseTargetAccu(computedFields, instrumentName) ?
            <CalendarHeaderInfosContainer title={strikeMessage} instrumentName={trade.instrumentName}>
              {!isSameStrikes && (
                <div className="mb-2">
                  <FormattedMessage id={`Multiple`} />
                </div>
              )}
              <FormattedMessage
                id={`app.calendar.${strikeMessage}`}
                values={{
                  value: strike,
                }}
              />
            </CalendarHeaderInfosContainer>
          : <>
              <CalendarHeaderInfosContainer title={`${strikeMessage}UpDown`} instrumentName={trade.instrumentName}>
                {!isSameStrikesDown && !isSameStrikesUp && <FormattedMessage id={`app.calendar.strikes.multiples.title`} />}
                <FormattedMessage
                  id={`app.calendar.${strikeMessage}UpDown`}
                  values={{
                    value: `${formatAmountWithPrecision(strikeDown ?? 0, 5)} / ${formatAmountWithPrecision(strikeUp ?? 0, 5)}`,
                  }}
                />
              </CalendarHeaderInfosContainer>
              <CalendarHeaderInfosContainer title={`KiUpDown`} instrumentName={trade.instrumentName}>
                {!isSameKiUps && !isSameKiDowns && <FormattedMessage id={`app.calendar.strikes.multiples.title`} />}
                <FormattedMessage
                  id={`app.calendar.KiUpDown`}
                  values={{
                    value: `${formatAmountWithPrecision(kiDown ?? 0, 5)} / ${formatAmountWithPrecision(kiUp ?? 0, 5)}`,
                  }}
                />
              </CalendarHeaderInfosContainer>
            </>
          }

          {instrumentName === 'FxoSquareTargetAccumulator' && (
            <CalendarHeaderInfosContainer title="leverage" instrumentName={trade.instrumentName}>
              <FormattedMessage
                id="app.calendar.leverage"
                values={{
                  value: isSameLeverage ? formatAmount(trade.leverage ?? 0) : 'Multiple',
                }}
              />
            </CalendarHeaderInfosContainer>
          )}

          <CalendarHeaderInfosContainer title="date" instrumentName={trade.instrumentName}>
            <FormattedMessage id="app.calendar.date" values={{ value: formattedDate }} />
          </CalendarHeaderInfosContainer>
        </div>
      </div>

      <div className="calendar-header-info">
        <div className="card-grid-row">
          {isTargetAccu(trade) && (
            <>
              <CalendarHeaderInfosContainer title="target" instrumentName={trade.instrumentName}>
                <FormattedMessage id="app.calendar.target" values={{ value: formatAmountWithPrecision(trade.target, 5) }} />
              </CalendarHeaderInfosContainer>
              <CalendarHeaderInfosContainer title="consumedTarget" instrumentName={trade.instrumentName}>
                <FormattedMessage id="app.calendar.consumedTarget" values={{ value: formatAmountWithPrecision(trade.consumedTarget, 5) }} />
              </CalendarHeaderInfosContainer>
              <CalendarHeaderInfosContainer title="remainingTarget" instrumentName={trade.instrumentName}>
                <FormattedMessage
                  id="app.calendar.remainingTarget"
                  values={{
                    value: formatAmountWithPrecision(trade.target - trade.consumedTarget, 5),
                  }}
                />
              </CalendarHeaderInfosContainer>
              <CalendarHeaderInfosContainer title="knockOutConvention" instrumentName={trade.instrumentName}>
                <FormattedMessage id={getknockOutConvention(description ?? '')} />
              </CalendarHeaderInfosContainer>
            </>
          )}

          {barrier && (
            <CalendarHeaderInfosContainer title="barrier" instrumentName={trade.instrumentName}>
              <FormattedMessage id="app.calendar.barrier" values={{ value: formatAmountWithPrecision(barrier, 5) }} />
            </CalendarHeaderInfosContainer>
          )}
          {deliveranceData && (
            <CalendarHeaderInfosContainer title="deliverance" instrumentName={trade.instrumentName}>
              <FormattedMessage id={deliveranceData} values={{ ccy1, ccy2 }} />
            </CalendarHeaderInfosContainer>
          )}
        </div>
      </div>
    </>
  );
}

function getknockOutConvention(description: string) {
  switch (true) {
    case description.includes('NONE'):
      return 'app.calendar.knockOutConvention.NONE';
    case description.includes('FULL'):
      return 'app.calendar.knockOutConvention.FULL';
    case description.includes('ADJUSTED_BY_AMOUNT'):
      return 'app.calendar.knockOutConvention.ADJUSTED_BY_AMOUNT';
    case description.includes('ADJUSTED_BY_STRIKE'):
      return 'app.calendar.knockOutConvention.ADJUSTED_BY_STRIKE';

    default:
      return 'app.calendar.knockOutConvention.NONE';
  }
}

const isNotFxoPauseTargetAccu = (
  computedFields:
    | DecoratedFields<FxoStandardForwardAccumulatorCalendarFields>[]
    | DecoratedFields<FxTargetAccumulatorCalendarFields>[]
    | DecoratedFields<FxoSquareTargetAccumulatorCalendarFields>[]
    | DecoratedFields<FxoPauseTargetAccumulatorCalendarFields>[],
  instrumentName: AccumulatorInstrumentName,
): computedFields is
  | DecoratedFields<FxoStandardForwardAccumulatorCalendarFields>[]
  | DecoratedFields<FxTargetAccumulatorCalendarFields>[]
  | DecoratedFields<FxoSquareTargetAccumulatorCalendarFields>[] => instrumentName !== 'FxoPauseTargetAccumulator';

export const getDeliveranceDate = (trade: AccumulatorTrade) => {
  switch (true) {
    case trade.description?.includes('SPOT'):
      return 'app.calendar.deliverance.SPOT';
    case trade.description?.includes('FWD'):
      return 'app.calendar.deliverance.FWD';
    case trade.description?.includes('CF1'):
      return 'app.calendar.deliverance.CF1';
    case trade.description?.includes('CF2'):
      return 'app.calendar.deliverance.CF2';

    default:
      break;
  }
};
