import { LogBuilder } from '@sgme/logger'
import { sgwtConnect } from '@/sgwtConnect';
import { getApiUrl, isTest } from '@/config/app.config';

let logBuilder = LogBuilder();
let crashLogBuilder = LogBuilder();

export const isNode = typeof process === 'object' && !!process.versions && !!process.versions.node

if (isTest) {
	logBuilder.enableConsole();
	crashLogBuilder.enableConsole();
} else {
	logBuilder = logBuilder
		.enableServerLogs({
			storeName: 'main',
			url: `${getApiUrl()}log`,
			prefix: '[MyFX] : ',
			scheduler: {
				defaultInterval: 10_000,
				retryPolicy: 'retryOnFail',
				batchSize: 500,
				range: {
					min: 5_000,
					max: 20_000,
				},
			}
		})
		.setAuthTokenFactory(() => {
			if (sgwtConnect.isAuthorized()) {
				const authToken = sgwtConnect.getAuthorizationHeader()
				if (authToken) return authToken
			}
			throw new Error('No authorization header or token. Disconnected ?!')
		})

	crashLogBuilder = crashLogBuilder
		.enableServerLogs({
			storeName: 'crashapp',
			url: `${getApiUrl()}log`,
			prefix: '[MyFX] : ',
			scheduler: {
				defaultInterval: 1_000,
				batchSize: 500,
				range: {
					min: 5_000,
					max: 20_000,
				},
				retryPolicy: 'retryOnFail',
			}
		})
		.setAuthTokenFactory(() => {
			if (sgwtConnect.isAuthorized()) {
				const authToken = sgwtConnect.getAuthorizationHeader()
				if (authToken) return authToken
			}
			throw new Error('No authorization header or token. Disconnected ?!')
		})
}

export const logger = logBuilder.build();
export const crashLogger = crashLogBuilder.build();