import 'core-js/es/array';
import 'core-js/es/symbol';
import 'core-js/es/array/includes';
import 'core-js/es/string/includes';

import * as React from 'react';
import { useEffect, useState } from 'react';

import { createRoot } from 'react-dom/client';

import { EVENT_AUTH_DISCARDED, EVENT_AUTH_EXPIRED, EVENT_AUTH_RENEW_ERROR, EVENT_AUTH_RENEW_SUCCESS } from '@sgwt/connect-core';
import { IntlProvider } from 'react-intl';
import { Provider, useSelector } from 'react-redux';
import { throwError } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

import { App } from '@/App/App';
import { logger } from '@/logging/logger';
import { connectToStreaming } from '@/services/streaming.service';
import { UserService } from '@/services/user.service';
import { sgwtConnect } from '@/sgwtConnect';
import { toggleNavigateAsModalAction } from '@/store/state/ui/ui.actions';
import { navigableAsUsersLoadedAction, userLoadedAction } from '@/store/state/user/user.actions';
import { getStore } from '@/store/store';
import '@/index.scss';
import { CrashModal } from '@/App/utils/CrashModal';
import { toLogEvents$ } from '@/services/streams.service';
import { streamingConnectedAction } from '@/store/state/streaming/streaming.actions';
import type { SgwtConnectHTMLElement } from '@/typings/sgwt-widgets';
import { messages } from '@/utils/locale';
import { ServiceLoader } from '@sgme/ui';
import { selectCurrentLocale } from './store/state/ui/ui.selectors';
import { initSgBootstrap } from './utils/theme';

if (sgwtConnect.isAuthorized()) {
  initAppForAuthorizedUser();
} else {
  displayErrorForUnauthorisedUser();
}

//  █████╗ ██╗   ██╗████████╗██╗  ██╗ ██████╗ ██████╗ ██╗███████╗███████╗██████╗     ██╗   ██╗███████╗███████╗██████╗
// ██╔══██╗██║   ██║╚══██╔══╝██║  ██║██╔═══██╗██╔══██╗██║╚══███╔╝██╔════╝██╔══██╗    ██║   ██║██╔════╝██╔════╝██╔══██╗
// ███████║██║   ██║   ██║   ███████║██║   ██║██████╔╝██║  ███╔╝ █████╗  ██║  ██║    ██║   ██║███████╗█████╗  ██████╔╝
// ██╔══██║██║   ██║   ██║   ██╔══██║██║   ██║██╔══██╗██║ ███╔╝  ██╔══╝  ██║  ██║    ██║   ██║╚════██║██╔══╝  ██╔══██╗
// ██║  ██║╚██████╔╝   ██║   ██║  ██║╚██████╔╝██║  ██║██║███████╗███████╗██████╔╝    ╚██████╔╝███████║███████╗██║  ██║
// ╚═╝  ╚═╝ ╚═════╝    ╚═╝   ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═╝╚═╝╚══════╝╚══════╝╚═════╝      ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝

function initAppForAuthorizedUser() {
  initSgBootstrap();
  subscribeToStreamEvents();
  subscribeToSGConnectEvents();

  const rootElement = document.getElementById('root');

  if (!rootElement) throw new Error('no root');

  const root = createRoot(rootElement);

  root.render(
    <React.StrictMode>
      <ServiceLoader>
        <Provider store={getStore()}>
          <ConnectedIntlProvider>
            <CrashModal />
            <Application />
          </ConnectedIntlProvider>
        </Provider>
      </ServiceLoader>
    </React.StrictMode>,
  );
}

function subscribeToStreamEvents() {
  connectToStreaming().then((streamingInfo) => {
    getStore().dispatch(streamingConnectedAction(streamingInfo));

    // logs some important events, useful for support
    toLogEvents$().subscribe((event) => logger.logInformation('Received event: {event}', event));
  });
}

function subscribeToSGConnectEvents() {
  sgwtConnect.on(EVENT_AUTH_RENEW_SUCCESS, () => logger.logInformation('sgwtConnect token renew'));

  sgwtConnect.on(EVENT_AUTH_DISCARDED, () => logger.logInformation('sgwtConnect token is no longer available on the client side.'));

  sgwtConnect.on(EVENT_AUTH_EXPIRED, () => logger.logInformation('sgwtConnect token is no longer valid.'));

  sgwtConnect.on(EVENT_AUTH_RENEW_ERROR, (error) => logger.logError('sgwtConnect failed to renew the token', JSON.stringify(error)));
}

function ConnectedIntlProvider({ children }: React.PropsWithChildren<Record<string, unknown>>) {
  const locale = useSelector(selectCurrentLocale);

  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      {children}
    </IntlProvider>
  );
}

type ApplicationStatus = 'loading' | 'ready' | 'no-company' | 'not-allowed' | 'error';

function Application() {
  const [status, setStatus] = useState<ApplicationStatus>('loading');

  useEffect(() => {
    const subscription = UserService.getCurrentUser().subscribe(
      (user) => {
        if (!user.myFx) {
          setStatus('not-allowed');
          return;
        }

        if (user.companies.length === 0) {
          setStatus('no-company');
          return;
        }

        getStore().dispatch(userLoadedAction(user));

        setupSgwtConnectWidget();

        if (!user.canNavigateAs) {
          setStatus('ready');
          return;
        }

        UserService.getNavigableAsUsers()
          .pipe(
            tap(() => getStore().dispatch(toggleNavigateAsModalAction())),
            finalize(() => setStatus('ready')),
          )
          .subscribe(
            (users) => getStore().dispatch(navigableAsUsersLoadedAction(users)),
            (err) => logger.logError('Error loading users', err),
          );
      },
      (error) => {
        if (error.status === 401 || error.status === 403) {
          setStatus('not-allowed');
        } else {
          setStatus('error');
        }

        return throwError(error);
      },
    );

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  switch (status) {
    case 'loading':
      return <div>loading...</div>;
    case 'ready':
      return <App />;
    case 'no-company':
      return <NoCompany />;
    case 'not-allowed':
      return <NotAllowed />;
    case 'error':
      return <ErrorComponent />;
  }
}

function setupSgwtConnectWidget() {
  const widget = document.querySelector<SgwtConnectHTMLElement>('sgwt-connect');

  if (widget) {
    // When the code is executed, the widget may not have been initialized. So, we need to check that, otherwise calling
    // `widget.setSgwtConnectInstance()` will throw an error.
    if (typeof widget.setSgwtConnectInstance === 'undefined') {
      // Widget is not initialized yet, so we will wait the event that indicates the widget is ready...
      const handleSgwtConnectReady = () => {
        widget.setSgwtConnectInstance(sgwtConnect);

        // Remove the event listener
        widget.removeEventListener('sgwt-connect--ready', handleSgwtConnectReady);
      };

      widget.addEventListener('sgwt-connect--ready', handleSgwtConnectReady);
    } else {
      // Widget is initialized...
      widget.setSgwtConnectInstance(sgwtConnect);
    }
  }
}

function NoCompany() {
  return <div className="center-screen">{`Vous n'avez pas de société rattachée à votre compte`}</div>;
}

function NotAllowed() {
  location.href = window.sgmeConfiguration.redirect_uri;

  return <div className="center-screen">{`Vous n'avez pas les droits d'accéder à la plateforme`}</div>;
}

function ErrorComponent() {
  return <div className="center-screen">Une erreur est survenue sur la plateforme, nous vous invitons à réessayer plus tard</div>;
}

// ███╗   ██╗ ██████╗ ████████╗     █████╗ ██╗   ██╗████████╗██╗  ██╗ ██████╗ ██████╗ ██╗███████╗███████╗██████╗     ██╗   ██╗███████╗███████╗██████╗
// ████╗  ██║██╔═══██╗╚══██╔══╝    ██╔══██╗██║   ██║╚══██╔══╝██║  ██║██╔═══██╗██╔══██╗██║╚══███╔╝██╔════╝██╔══██╗    ██║   ██║██╔════╝██╔════╝██╔══██╗
// ██╔██╗ ██║██║   ██║   ██║       ███████║██║   ██║   ██║   ███████║██║   ██║██████╔╝██║  ███╔╝ █████╗  ██║  ██║    ██║   ██║███████╗█████╗  ██████╔╝
// ██║╚██╗██║██║   ██║   ██║       ██╔══██║██║   ██║   ██║   ██╔══██║██║   ██║██╔══██╗██║ ███╔╝  ██╔══╝  ██║  ██║    ██║   ██║╚════██║██╔══╝  ██╔══██╗
// ██║ ╚████║╚██████╔╝   ██║       ██║  ██║╚██████╔╝   ██║   ██║  ██║╚██████╔╝██║  ██║██║███████╗███████╗██████╔╝    ╚██████╔╝███████║███████╗██║  ██║
// ╚═╝  ╚═══╝ ╚═════╝    ╚═╝       ╚═╝  ╚═╝ ╚═════╝    ╚═╝   ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═╝╚═╝╚══════╝╚══════╝╚═════╝      ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝

function displayErrorForUnauthorisedUser() {
  const authError = sgwtConnect.getAuthorizationError();

  if (authError) {
    // do something meaningful with the error
    // eslint-disable-next-line no-alert
    alert(JSON.stringify(authError));
  } else {
    sgwtConnect.requestAuthorization();
  }
}
