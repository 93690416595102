import { useSelector } from 'react-redux';
import { createAlert, createAlertFormUpdate } from '@/store/state/create-alert/create-alert.actions';
import { canSubmit, CreateAlertFormData } from '@/store/state/create-alert/create-alert.model';
import { Currency } from '@/models/currency';
import { createAlertChangeCurrencyThunk } from '@/store/state/create-alert/create-alert.thunks';
import { useFormattingContext } from '@/utils/format';
import { AlertStateFormEditor } from './AlertStateFormEditor';
import { WillSendMailTo } from './WillSendMailTo';
import { CreateButtonList } from './ButtonList';
import { BottomInfo } from './BottomInfo';
import { Piwik } from '@/utils/piwik';
import { canModifyAlert, selectCurrentUserEmail, selectRfsCurrencies } from '@/store/state/user/user.selectors';
import { getDisplayableAlerts, selectCreateAlert } from '@/store/state/alerts/alerts.selectors';
import { MainLayout } from '@/App/Layout/MainLayout';
import { selectPendingRedirectionUrl } from '@/store/state/ui/ui.selectors';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { routes } from '@/App/routes';
import { useAppDispatch } from '@/store/store';
import { divideAlerts, getIsSameLimitPrice } from '@/utils/alerts';

interface Handlers {
  doCreateAlertFormUpdate: (p: Partial<CreateAlertFormData>) => void;
  doCreateAlertChangeCurrency: (way: 'base' | 'contra', currency: Currency) => void;
  doCreateAlert: () => void;
}

const useHandlers = (): Handlers => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return {
    doCreateAlertFormUpdate: (p: Partial<CreateAlertFormData>) => dispatch(createAlertFormUpdate(p)),
    doCreateAlertChangeCurrency: (way: 'base' | 'contra', currency: Currency) => dispatch(createAlertChangeCurrencyThunk({ way, currency })),
    doCreateAlert: () => {
      dispatch(createAlert());
      navigate(routes.ALERTS);
    },
  };
};

export function CreateAlertForm() {
  const { formatAmount } = useFormattingContext();
  const alertForm = useSelector(selectCreateAlert);
  const currencies = useSelector(selectRfsCurrencies);
  const email = useSelector(selectCurrentUserEmail);
  const fixingExample = alertForm.fixingExample !== undefined ? formatAmount(alertForm.fixingExample) : undefined;

  const alerts = useSelector(getDisplayableAlerts);
  const [editedlimitPrice, setEditedLimitPrice] = useState<number | null>(null);

  const [liveAlerts] = useMemo(() => divideAlerts(alerts), [alerts]);

  const isSameLimitPrice = getIsSameLimitPrice(liveAlerts, editedlimitPrice, alertForm.data.baseCurrency, alertForm.data.contraCurrency);

  const readonly = useSelector(canModifyAlert) === false;

  const navigate = useNavigate();
  const pendingRedirectionUrl = useSelector(selectPendingRedirectionUrl);
  const { doCreateAlertFormUpdate, doCreateAlertChangeCurrency, doCreateAlert } = useHandlers();

  const onCreateAlert = useCallback(() => {
    Piwik.trackAction('Created alert');
    doCreateAlert();
  }, [doCreateAlert]);

  useEffect(() => {
    if (pendingRedirectionUrl !== undefined) {
      navigate(pendingRedirectionUrl);
    }
  }, [pendingRedirectionUrl]);

  return (
    <MainLayout>
      <div className="mt-4">
        <WillSendMailTo email={email} />
        <AlertStateFormEditor
          alertForm={alertForm}
          currencies={currencies}
          fixingExample={fixingExample}
          createAlertFormUpdate={doCreateAlertFormUpdate}
          createAlertChangeCurrency={doCreateAlertChangeCurrency}
          isSameLimitPrice={isSameLimitPrice}
          setEditedLimitPrice={setEditedLimitPrice}
        />
        <CreateButtonList canSubmit={canSubmit(alertForm.validation)} createAlert={onCreateAlert} currently={alertForm.curently} readonly={readonly || isSameLimitPrice} />
        <BottomInfo />
      </div>
    </MainLayout>
  );
}
