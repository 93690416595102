import * as React from 'react';
import { useSelector } from 'react-redux';
import { createAlertFormUpdate, deleteAlertAction, updateAlert } from '@/store/state/create-alert/create-alert.actions';
import { canSubmit, CreateAlertFormData } from '@/store/state/create-alert/create-alert.model';

import { Currency } from '@/models/currency';
import { createAlertChangeCurrencyThunk } from '@/store/state/create-alert/create-alert.thunks';
import { useFormattingContext } from '@/utils/format';
import { AlertStateFormEditor } from './AlertStateFormEditor';
import { WillSendMailTo } from './WillSendMailTo';
import { UpdateButtonList } from './ButtonList';
import { BottomInfo } from './BottomInfo';
import { Piwik } from '@/utils/piwik';
import { canModifyAlert, selectCurrentUserEmail, selectRfsCurrencies } from '@/store/state/user/user.selectors';
import { getDisplayableAlerts, selectCreateAlert } from '@/store/state/alerts/alerts.selectors';
import { MainLayout } from '@/App/Layout/MainLayout';
import { useNavigate } from 'react-router';
import { routes } from '@/App/routes';
import { useAppDispatch } from '@/store/store';
import { divideAlerts, getIsSameLimitPrice } from '@/utils/alerts';

interface Handlers {
  doDeleteAlert: (id: string) => void;
  doUpdateAlert: () => void;
  doCreateAlertFormUpdate: (p: Partial<CreateAlertFormData>) => void;
  doCreateAlertChangeCurrency: (way: 'base' | 'contra', currency: Currency) => void;
}

const useHandlers = (): Handlers => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return {
    doCreateAlertFormUpdate: (p: Partial<CreateAlertFormData>) => dispatch(createAlertFormUpdate(p)),
    doCreateAlertChangeCurrency: (way: 'base' | 'contra', currency: Currency) => dispatch(createAlertChangeCurrencyThunk({ way, currency })),
    doUpdateAlert: () => {
      dispatch(updateAlert());
      navigate(routes.ALERTS);
    },
    doDeleteAlert: (id: string) => {
      dispatch(deleteAlertAction(id));
      navigate(routes.ALERTS);
    },
  };
};

export function UpdateAlertForm() {
  const { formatAmount } = useFormattingContext();
  const alertForm = useSelector(selectCreateAlert);
  const currencies = useSelector(selectRfsCurrencies);
  const email = useSelector(selectCurrentUserEmail);
  const fixingExample = alertForm.fixingExample !== undefined ? formatAmount(alertForm.fixingExample) : undefined;
  const readonly = useSelector(canModifyAlert) === false;
  const [editedlimitPrice, setLEditedimitPrice] = React.useState<number | null>(null);
  const alerts = useSelector(getDisplayableAlerts);

  const [liveAlerts] = React.useMemo(() => divideAlerts(alerts), [alerts]);

  const isSameLimitPrice = getIsSameLimitPrice(liveAlerts, editedlimitPrice, alertForm.data.baseCurrency, alertForm.data.contraCurrency);

  const { doDeleteAlert, doUpdateAlert, doCreateAlertFormUpdate, doCreateAlertChangeCurrency } = useHandlers();

  const onDeleteAlert = React.useCallback(() => {
    Piwik.trackAction('Deleted alert');
    doDeleteAlert(alertForm.id!);
  }, [alertForm.id, doDeleteAlert]);

  const onUpdateAlert = React.useCallback(() => {
    Piwik.trackAction('Updated alert');
    doUpdateAlert();
  }, [doUpdateAlert]);
  return (
    <MainLayout>
      <div className="mt-4">
        <WillSendMailTo email={email} />
        <AlertStateFormEditor
          alertForm={alertForm}
          currencies={currencies}
          fixingExample={fixingExample}
          createAlertFormUpdate={doCreateAlertFormUpdate}
          createAlertChangeCurrency={doCreateAlertChangeCurrency}
          onlyLimitPriceEditable
          isSameLimitPrice={isSameLimitPrice}
          setEditedLimitPrice={setLEditedimitPrice}
        />
        <UpdateButtonList
          canSubmit={canSubmit(alertForm.validation)}
          updateAlert={onUpdateAlert}
          deleteAlert={onDeleteAlert}
          currently={alertForm.curently}
          readonly={readonly || isSameLimitPrice}
        />
        <BottomInfo />
      </div>
    </MainLayout>
  );
}
